var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: { title: "사업장", value: _vm.fireFighting.plantName },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "관리번호",
                    value: _vm.fireFighting.manageNo,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "설비유형",
                    value: _vm.fireFighting.sopFireFightingTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-2" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "설비종류",
                    value: _vm.fireFighting.sopFireFightingTypeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-4" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "소방시설 및 장비명",
                    value: _vm.fireFighting.fireFightingName,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "점검이력",
          columns: _vm.grid.columns,
          data: _vm.fireFighting.histroy,
          gridHeight: _vm.gridHeight,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }